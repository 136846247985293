import ObjectUtil from "./ObjectUtil"

export default class CollectionUtil {

  static mapNotNullOrUndefined<T, R>(array: T[], transform: (element: T) => R | null | undefined): Array<R> {
    const results = Array<R>()
    for (let element of array) {
      const value = transform(element)
      if (ObjectUtil.isNotNullOrUndefined(value)) {
        results.push(value)
      }
    }
    return results
  }

  static associateTo<T, K, V, M extends Map<K, V>>(array: T[], destination: M, transform: (element: T) => [K, V]): M {
    for (let element of array) {
      const entry = transform(element)
      destination.set(entry[0], entry[1])
    }
    return destination
  }

  static associate<T, K, V>(array: T[], transform: (element: T) => [K, V]): Map<K, V> {
    return this.associateTo(array, new Map<K, V>(), transform)
  }

  static associateBy<T, K>(receiver: T[], keySelector: (element: T) => K): Map<K, T> {
    return this.associate(receiver, (it) => [keySelector(it), it])
  }
}
