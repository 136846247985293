import {
  Bucket,
  BucketId,
  Container,
  ContainerGroup,
  ContainerId,
  EventKey,
  EventType,
  Experiment,
  ExperimentKey,
  ExperimentStatus,
  ExperimentType,
  MATCH_OPERATORS,
  MATCH_TYPES,
  MATCH_VALUE_TYPES,
  ParameterConfiguration,
  ParameterConfigurationId,
  RemoteConfigParameter,
  RemoteConfigParameterKey,
  RemoteConfigParameterValue,
  RemoteConfigTargetRule,
  Segment,
  SEGMENT_TYPES,
  SegmentKey,
  Slot,
  Target,
  TARGET_ACTION_TYPES,
  TARGET_KEY_TYPES,
  TargetAction,
  TargetCondition,
  TargetingType,
  TargetKey,
  TargetMatch,
  TargetRule,
  Variation
} from "../model/model"
import {
  BucketDto,
  ContainerDto,
  ContainerGroupDto,
  ExperimentDto,
  ParameterConfigurationDto,
  RemoteConfigParameterDto,
  RemoteConfigParameterValueDto,
  RemoteConfigTargetRuleDto,
  SegmentDto,
  TargetActionDto,
  TargetConditionDto,
  TargetDto,
  TargetKeyDto,
  TargetMatchDto,
  TargetRuleDto,
  WorkspaceDto
} from "./dto"
import Logger from "../logger"
import CollectionUtil from "../util/CollectionUtil"

const log = Logger.log

export default class Workspace {
  private experiments: Map<ExperimentKey, Experiment>
  private featureFlags: Map<ExperimentKey, Experiment>
  private buckets: Map<BucketId, Bucket>
  private eventTypes: Map<EventKey, EventType>
  private segments: Map<SegmentKey, Segment>
  private containers: Map<ContainerId, Container>
  private parameterConfigurations: Map<ParameterConfigurationId, ParameterConfiguration>
  private remoteConfigParameters: Map<RemoteConfigParameterKey, RemoteConfigParameter>

  constructor(
    experiments: Map<ExperimentKey, Experiment>,
    featureFlags: Map<ExperimentKey, Experiment>,
    buckets: Map<BucketId, Bucket>,
    eventTypes: Map<EventKey, EventType>,
    segments: Map<SegmentKey, Segment>,
    containers: Map<ContainerId, Container>,
    parameterConfigurations: Map<ParameterConfigurationId, ParameterConfiguration>,
    remoteConfigParameters: Map<RemoteConfigParameterKey, RemoteConfigParameter>
  ) {
    this.experiments = experiments
    this.featureFlags = featureFlags
    this.buckets = buckets
    this.eventTypes = eventTypes
    this.segments = segments
    this.containers = containers
    this.parameterConfigurations = parameterConfigurations
    this.remoteConfigParameters = remoteConfigParameters
  }

  getExperimentOrNull(experimentKey: ExperimentKey): Experiment | undefined {
    return this.experiments.get(experimentKey)
  }

  getFeatureFlagOrNull(featureKey: ExperimentKey): Experiment | undefined {
    return this.featureFlags.get(featureKey)
  }

  getBucketOrNull(bucketId: BucketId): Bucket | undefined {
    return this.buckets.get(bucketId)
  }

  getEventTypeOrNull(eventKey: EventKey): EventType | undefined {
    return this.eventTypes.get(eventKey)
  }

  getSegmentOrNull(segmentKey: SegmentKey): Segment | undefined {
    return this.segments.get(segmentKey)
  }

  getContainerOrNull(containerId: ContainerId): Container | undefined {
    return this.containers.get(containerId)
  }

  getParameterConfigurationOrNull(
    parameterConfigurationId: ParameterConfigurationId
  ): ParameterConfiguration | undefined {
    return this.parameterConfigurations.get(parameterConfigurationId)
  }

  getRemoteConfigParameterOrNull(remoteConfigParameterKey: string): RemoteConfigParameter | undefined {
    return this.remoteConfigParameters.get(remoteConfigParameterKey)
  }

  static from(dto: WorkspaceDto): Workspace {
    const buckets = CollectionUtil.associate(dto.buckets, (it) => [it.id, this.toBucket(it)])

    const experiments: Map<ExperimentKey, Experiment> = CollectionUtil.associateBy(
      CollectionUtil.mapNotNullOrUndefined(dto.experiments, (it) => this.toExperimentOrNull("AB_TEST", it)),
      (it) => it.key
    )

    const featureFlags: Map<ExperimentKey, Experiment> = CollectionUtil.associateBy(
      CollectionUtil.mapNotNullOrUndefined(dto.featureFlags, (it) => this.toExperimentOrNull("FEATURE_FLAG", it)),
      (it) => it.key
    )

    const eventTypes = CollectionUtil.associate(dto.events, (it) => [it.key, new EventType(it.id, it.key)])

    const segments: Map<SegmentKey, Segment> = CollectionUtil.associateBy(
      CollectionUtil.mapNotNullOrUndefined(dto.segments, (it) => this.toSegmentOrNull(it)),
      (it) => it.key
    )

    const containers: Map<ContainerId, Container> = CollectionUtil.associate(dto.containers, (it) => [
      it.id,
      this.toContainer(it)
    ])

    const parameterConfigurations: Map<ParameterConfigurationId, ParameterConfiguration> = CollectionUtil.associate(
      dto.parameterConfigurations,
      (it) => [it.id, this.toParameterConfiguration(it)]
    )

    const remoteConfigParameters: Map<RemoteConfigParameterKey, RemoteConfigParameter> = CollectionUtil.associateBy(
      CollectionUtil.mapNotNullOrUndefined(dto.remoteConfigParameters, (it) => this.toRemoteConfigParameterOrNull(it)),
      (it) => it.key
    )

    return new Workspace(
      experiments,
      featureFlags,
      buckets,
      eventTypes,
      segments,
      containers,
      parameterConfigurations,
      remoteConfigParameters
    )
  }

  private static toBucket(dto: BucketDto): Bucket {
    return new Bucket(
      dto.seed,
      dto.slotSize,
      dto.slots.map(
        ({ startInclusive, endExclusive, variationId }) => new Slot(startInclusive, endExclusive, variationId)
      )
    )
  }

  private static toExperimentOrNull(type: ExperimentType, dto: ExperimentDto): Experiment | undefined {
    const experimentStatus = this.experimentStatusOrNull(dto.execution.status)
    const variations = dto.variations.map(
      (it) => new Variation(it.id, it.key, it.status === "DROPPED", it.parameterConfigurationId)
    )
    const userOverrides = CollectionUtil.associate(dto.execution.userOverrides, (it) => [it.userId, it.variationId])
    const segmentOverrides = CollectionUtil.mapNotNullOrUndefined(dto.execution.segmentOverrides, (it) =>
      this.toTargetRuleOrNull(it, TargetingType.IDENTIFIER)
    )
    const targetAudiences = CollectionUtil.mapNotNullOrUndefined(dto.execution.targetAudiences, (it) =>
      this.toTargetOrNull(it, TargetingType.PROPERTY)
    )
    const targetRules = CollectionUtil.mapNotNullOrUndefined(dto.execution.targetRules, (it) =>
      this.toTargetRuleOrNull(it, TargetingType.PROPERTY)
    )
    const defaultRule = this.toTargetActionOrNull(dto.execution.defaultRule)
    return (
      experimentStatus &&
      defaultRule &&
      new Experiment(
        dto.id,
        dto.key,
        type,
        dto.identifierType,
        experimentStatus,
        dto.version,
        variations,
        userOverrides,
        segmentOverrides,
        targetAudiences,
        targetRules,
        defaultRule,
        dto.containerId,
        dto.winnerVariationId
      )
    )
  }

  private static experimentStatusOrNull(executionStatus: string): ExperimentStatus | undefined {
    switch (executionStatus) {
      case "READY":
        return "DRAFT"
      case "RUNNING":
        return "RUNNING"
      case "PAUSED":
        return "PAUSED"
      case "STOPPED":
        return "COMPLETED"
      default:
        log.debug(`Unsupported status [${executionStatus}]`)
        return undefined
    }
  }

  private static toTargetRuleOrNull(dto: TargetRuleDto, targetingType: TargetingType): TargetRule | undefined {
    const target = this.toTargetOrNull(dto.target, targetingType)
    const action = this.toTargetActionOrNull(dto.action)
    return target && action && new TargetRule(target, action)
  }

  private static toTargetActionOrNull(dto: TargetActionDto): TargetAction | undefined {
    const type = this.parseOrNull(TARGET_ACTION_TYPES, dto.type)
    return type && new TargetAction(type, dto.variationId, dto.bucketId)
  }

  private static toTargetOrNull(dto: TargetDto, targetingType: TargetingType): Target | undefined {
    const conditions = CollectionUtil.mapNotNullOrUndefined(dto.conditions, (it) =>
      this.toConditionOrNull(it, targetingType)
    )
    return new Target(conditions)
  }

  private static toConditionOrNull(dto: TargetConditionDto, targetingType: TargetingType): TargetCondition | undefined {
    const key = this.toTargetKeyOrNull(dto.key)
    if (!key) {
      return undefined
    }
    if (!targetingType.supports(key.type)) {
      return undefined
    }
    const match = this.toTargetMatchOrNull(dto.match)
    return match && new TargetCondition(key, match)
  }

  private static toTargetKeyOrNull(dto: TargetKeyDto): TargetKey | undefined {
    const keyType = this.parseOrNull(TARGET_KEY_TYPES, dto.type)
    return keyType && new TargetKey(keyType, dto.name)
  }

  private static toTargetMatchOrNull(dto: TargetMatchDto): TargetMatch | undefined {
    const matchType = this.parseOrNull(MATCH_TYPES, dto.type)
    const operator = this.parseOrNull(MATCH_OPERATORS, dto.operator)
    const valueType = this.parseOrNull(MATCH_VALUE_TYPES, dto.valueType)
    return matchType && operator && valueType && new TargetMatch(matchType, operator, valueType, dto.values)
  }

  private static toSegmentOrNull(dto: SegmentDto): Segment | undefined {
    const segmentType = this.parseOrNull(SEGMENT_TYPES, dto.type)
    return (
      segmentType &&
      new Segment(
        dto.id,
        dto.key,
        segmentType,
        CollectionUtil.mapNotNullOrUndefined(dto.targets, (it) => this.toTargetOrNull(it, TargetingType.SEGMENT))
      )
    )
  }

  private static toContainer(dto: ContainerDto): Container {
    return new Container(
      dto.id,
      dto.bucketId,
      dto.groups.map((it) => this.toContainerGroup(it))
    )
  }

  private static toContainerGroup(dto: ContainerGroupDto): ContainerGroup {
    return new ContainerGroup(dto.id, dto.experiments)
  }

  private static toParameterConfiguration(dto: ParameterConfigurationDto): ParameterConfiguration {
    return new ParameterConfiguration(
      dto.id,
      CollectionUtil.associate(dto.parameters, (it) => [it.key, it.value])
    )
  }

  private static toRemoteConfigParameterOrNull(dto: RemoteConfigParameterDto): RemoteConfigParameter | undefined {
    const remoteConfigParameterType = this.parseOrNull(MATCH_VALUE_TYPES, dto.type)
    const targetRules = CollectionUtil.mapNotNullOrUndefined(dto.targetRules, (it) =>
      this.toRemoteConfigTargetRuleOrNull(it, TargetingType.PROPERTY)
    )
    const defaultValue = this.toRemoteConfigParameterValueOrNull(dto.defaultValue)
    return (
      remoteConfigParameterType &&
      new RemoteConfigParameter(
        dto.id,
        dto.key,
        remoteConfigParameterType,
        dto.identifierType,
        targetRules,
        defaultValue
      )
    )
  }

  private static toRemoteConfigTargetRuleOrNull(
    dto: RemoteConfigTargetRuleDto,
    targetingType: TargetingType
  ): RemoteConfigTargetRule | undefined {
    const target = this.toTargetOrNull(dto.target, targetingType)
    return (
      target &&
      new RemoteConfigTargetRule(
        dto.key,
        dto.name,
        target,
        dto.bucketId,
        this.toRemoteConfigParameterValueOrNull(dto.value)
      )
    )
  }

  private static toRemoteConfigParameterValueOrNull(dto: RemoteConfigParameterValueDto): RemoteConfigParameterValue {
    return new RemoteConfigParameterValue(dto.id, dto.value)
  }

  private static parseOrNull<T extends string>(types: readonly T[], type: string): T | undefined {
    const t = types.find((it) => it === type)
    if (!t) {
      log.debug(`Unsupported type [${type}]. Please use the latest version of sdk.`)
    }
    return t
  }
}
