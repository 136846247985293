import TargetMatcher from "../match/TargetMatcher"
import { ConditionMatcherFactory } from "../match/ConditionMatcher"
import ActionResolver from "../action/ActionResolver"
import Bucketer from "../bucket/Bucketer"
import EvaluationFlow from "./EvaluationFlow"
import {
  CompletedEvaluator,
  ContainerEvaluator,
  DefaultRuleEvaluator,
  DraftEvaluator,
  ExperimentTargetEvaluator,
  IdentifierEvaluator,
  OverrideEvaluator,
  PausedEvaluator,
  TargetRuleEvaluator,
  TrafficAllocateEvaluator
} from "./FlowEvaluator"
import ExperimentTargetDeterminer from "../target/ExperimentTargetDeterminer"
import TargetRuleDeterminer from "../target/TargetRuleDeterminer"
import { ExperimentType } from "../../model/model"
import OverrideResolver from "../target/OverrideResolver"
import ContainerResolver from "../container/ContainerResolver"
import RemoteConfigParameterTargetRuleDeterminer from '../target/RemoteConfigParameterTargetRuleDeterminer'

export default class EvaluationFlowFactory {

  private readonly abTestFlow: EvaluationFlow
  private readonly featureFlagFlow: EvaluationFlow
  private readonly remoteConfigParameterTargetRuleDeterminer: RemoteConfigParameterTargetRuleDeterminer

  constructor() {

    const bucketer = new Bucketer()
    const targetMatcher = new TargetMatcher(new ConditionMatcherFactory())
    const actionResolver = new ActionResolver(bucketer)
    const overrideResolver = new OverrideResolver(targetMatcher, actionResolver)
    const containerResolver = new ContainerResolver(bucketer)

    this.abTestFlow = EvaluationFlow.of(
      new OverrideEvaluator(overrideResolver),
      new IdentifierEvaluator(),
      new ContainerEvaluator(containerResolver),
      new ExperimentTargetEvaluator(new ExperimentTargetDeterminer(targetMatcher)),
      new DraftEvaluator(),
      new PausedEvaluator(),
      new CompletedEvaluator(),
      new TrafficAllocateEvaluator(actionResolver)
    )

    this.featureFlagFlow = EvaluationFlow.of(
      new DraftEvaluator(),
      new PausedEvaluator(),
      new CompletedEvaluator(),
      new OverrideEvaluator(overrideResolver),
      new IdentifierEvaluator(),
      new TargetRuleEvaluator(new TargetRuleDeterminer(targetMatcher), actionResolver),
      new DefaultRuleEvaluator(actionResolver)
    )

    this.remoteConfigParameterTargetRuleDeterminer = new RemoteConfigParameterTargetRuleDeterminer(targetMatcher, bucketer)
  }

  getFlow(experimentType: ExperimentType): EvaluationFlow {
    switch (experimentType) {
      case "AB_TEST":
        return this.abTestFlow
      case "FEATURE_FLAG":
        return this.featureFlagFlow
    }
  }

  getRemoteConfigParameterTargetRuleDeterminer(): RemoteConfigParameterTargetRuleDeterminer {
    return this.remoteConfigParameterTargetRuleDeterminer
  }
}
