import TargetMatcher from '../match/TargetMatcher'
import Workspace from '../../workspace/Workspace'
import { HackleUser, RemoteConfigParameter, RemoteConfigTargetRule } from '../../model/model'
import Bucketer from '../bucket/Bucketer'
import ObjectUtil from '../../util/ObjectUtil'

export default class RemoteConfigParameterTargetRuleDeterminer {
  private targetMatcher: TargetMatcher
  private bucketer: Bucketer

  constructor (targetMatcher: TargetMatcher, bucketer: Bucketer) {
    this.targetMatcher = targetMatcher
    this.bucketer = bucketer
  }

  determineTargetRuleOrNull(workspace: Workspace, remoteConfigParameter: RemoteConfigParameter, user: HackleUser): RemoteConfigTargetRule | undefined {
    return remoteConfigParameter.targetRules.find((it) => this.matches(it, workspace, remoteConfigParameter, user))
  }

  private matches(targetRule: RemoteConfigTargetRule, workspace: Workspace, remoteConfigParameter: RemoteConfigParameter, user: HackleUser): boolean {
    if(!this.targetMatcher.matches(targetRule.target, workspace, user)) {
      return false
    }

    const identifier = user.identifiers[remoteConfigParameter.identifierType]

    if(ObjectUtil.isNullOrUndefined(identifier)) {
      return false
    }

    const bucket = workspace.getBucketOrNull(targetRule.bucketId)
    if (ObjectUtil.isNullOrUndefined(bucket)) {
      throw new Error(`bucket[${targetRule.bucketId}]`)
    }

    return ObjectUtil.isNotNullOrUndefined(this.bucketer.bucketing(bucket, identifier))
  }
}