export default class ObjectUtil {

  static isNotNullOrUndefined<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined
  }

  static isNullOrUndefined<T>(value: T | null | undefined): value is (null | undefined) {
    return value === null || value === undefined
  }

  static requiredNotNullOrUndefined<T>(
    value: T | null | undefined,
    message: () => string = () => "Required value is null or undefined"
  ): T {
    if (this.isNullOrUndefined(value)) {
      throw new Error(message())
    }
    return value
  }
}
