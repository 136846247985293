import { HackleUser, Segment, Target, TargetCondition, TargetKey, TargetKeyType } from "../../model/model"
import Workspace from "../../workspace/Workspace"
import ValueOperatorMatcher from "./ValueOperatorMatcher"
import { OperatorMatcherFactory } from "./OperatorMatcher"
import { ValueMatcherFactory } from "./ValueMatcher"
import ObjectUtil from "../../util/ObjectUtil"

export default interface ConditionMatcher {
  matches(condition: TargetCondition, workspace: Workspace, user: HackleUser): boolean
}

export class UserConditionMatcher implements ConditionMatcher {

  private userValueResolver: UserValueResolver
  private valueOperatorMatcher: ValueOperatorMatcher


  constructor(userValueResolver: UserValueResolver, valueOperatorMatcher: ValueOperatorMatcher) {
    this.userValueResolver = userValueResolver
    this.valueOperatorMatcher = valueOperatorMatcher
  }

  matches(condition: TargetCondition, workspace: Workspace, user: HackleUser): boolean {
    const userValue = this.userValueResolver.resolveOrNull(user, condition.key)
    if (ObjectUtil.isNullOrUndefined(userValue)) {
      return false
    }
    return this.valueOperatorMatcher.matches(userValue, condition.match)
  }
}

export class UserValueResolver {
  resolveOrNull(user: HackleUser, key: TargetKey): any | undefined {
    switch (key.type) {
      case "USER_ID":
        return user.identifiers[key.name]
      case "USER_PROPERTY":
        return user.properties[key.name]
      case "HACKLE_PROPERTY":
        return user.hackleProperties[key.name]
      case "SEGMENT":
        throw new Error(`Unsupported TargetKeyType [${key.type}]`)
    }
  }
}

export class SegmentConditionMatcher implements ConditionMatcher {

  private segmentMatcher: SegmentMatcher

  constructor(segmentMatcher: SegmentMatcher) {
    this.segmentMatcher = segmentMatcher
  }

  matches(condition: TargetCondition, workspace: Workspace, user: HackleUser): boolean {
    if (condition.key.type != "SEGMENT") {
      throw new Error(`Unsupported TargetKeyType [${condition.key.type}]`)
    }

    const isMatched = condition.match.values.some((it) => this._matches(it, workspace, user))

    switch (condition.match.type) {
      case "MATCH":
        return isMatched
      case "NOT_MATCH":
        return !isMatched
    }
  }

  _matches(value: any, workspace: Workspace, user: HackleUser): boolean {
    if (typeof value !== "string") {
      throw new Error(`SegmentKey[${value}]`)
    }
    const segment = workspace.getSegmentOrNull(value)
    if (!segment) {
      throw new Error(`Segment[${value}]`)
    }
    return this.segmentMatcher.matches(segment, workspace, user)
  }
}

export class SegmentMatcher {
  private userConditionMatcher: UserConditionMatcher

  constructor(userConditionMatcher: UserConditionMatcher) {
    this.userConditionMatcher = userConditionMatcher
  }

  matches(segment: Segment, workspace: Workspace, user: HackleUser): boolean {
    return segment.targets.some((it) => this._matches(it, workspace, user))
  }

  _matches(target: Target, workspace: Workspace, user: HackleUser): boolean {
    return target.conditions.every((it) => this.userConditionMatcher.matches(it, workspace, user))
  }
}

export class ConditionMatcherFactory {

  private static USER_CONDITION_MATCHER = new UserConditionMatcher(
    new UserValueResolver(),
    new ValueOperatorMatcher(new ValueMatcherFactory(), new OperatorMatcherFactory())
  )

  private static SEGMENT_CONDITION_MATCHER = new SegmentConditionMatcher(
    new SegmentMatcher(ConditionMatcherFactory.USER_CONDITION_MATCHER)
  )

  getMatcher(type: TargetKeyType): ConditionMatcher {
    switch (type) {
      case "USER_ID":
        return ConditionMatcherFactory.USER_CONDITION_MATCHER
      case "USER_PROPERTY":
        return ConditionMatcherFactory.USER_CONDITION_MATCHER
      case "HACKLE_PROPERTY":
        return ConditionMatcherFactory.USER_CONDITION_MATCHER
      case "SEGMENT":
        return ConditionMatcherFactory.SEGMENT_CONDITION_MATCHER
    }
  }
}
