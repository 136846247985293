import Workspace from "../../workspace/Workspace"
import { DecisionReason, Experiment, HackleUser, VariationKey } from "../../model/model"
import { Evaluation } from "../Evaluator"
import FlowEvaluator from "./FlowEvaluator"

export default class EvaluationFlow {

  readonly flowEvaluator: FlowEvaluator | undefined
  readonly nextFlow: EvaluationFlow | undefined

  constructor(flowEvaluator: FlowEvaluator | undefined = undefined, nextFlow: EvaluationFlow | undefined = undefined) {
    this.flowEvaluator = flowEvaluator
    this.nextFlow = nextFlow
  }

  evaluate(
    workspace: Workspace,
    experiment: Experiment,
    user: HackleUser,
    defaultVariationKey: VariationKey
  ): Evaluation {
    if (this.flowEvaluator && this.nextFlow) {
      return this.flowEvaluator.evaluate(workspace, experiment, user, defaultVariationKey, this.nextFlow)
    } else {
      return Evaluation.of(workspace, experiment, defaultVariationKey, DecisionReason.TRAFFIC_NOT_ALLOCATED)
    }
  }

  static of(...evaluators: FlowEvaluator[]): EvaluationFlow {
    let flow = new EvaluationFlow()
    for (const flowEvaluator of evaluators.reverse()) {
      flow = new EvaluationFlow(flowEvaluator, flow)
    }
    return flow
  }
}
